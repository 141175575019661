import './App.css'
import Nav from './nav'
import About from './About'
import Mint from './Mint'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Home'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSkull, faCoffee } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faSkull, faCoffee)

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/mint" element={<Mint />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
