import './App.css'

function Photos() {
  return (
    <><div>
      <h1>Dark</h1>
      <div>Dark theme descriptions</div>
        <div className='image-grid'>
          <img src="images/dark/1992.png"></img>
          <img src="images/dark/3122.png"></img>
          <img src="images/dark/4509.png"></img>
        </div>
      <h1>Venom</h1>
      <div className='image-grid'>
        <img src="images/venom/5.png"></img>
        <img src="images/venom/27.png"></img>
        <img src="images/venom/56.png"></img>
      </div>
      <h1>Venom Mini</h1>
      <div className='image-grid'>
        <img src="images/venom-mini/4.png"></img>
        <img src="images/venom-mini/70.png"></img>
        <img src="images/venom-mini/102.png"></img>
      </div>
      <h1>Icecream</h1>
      <div className='image-grid'>
        <img src="images/icecream/100.png"></img>
        <img src="images/icecream/50.png"></img>
        <img src="images/icecream/70.png"></img>
      </div>
    </div></>
  )
}

export default Photos