import React from 'react'
import Photos from './photos'

function About() {
  return (
    <div className='image-background'>
      <Photos></Photos>
      <div>Offhell Studio 2022</div>
    </div>
  )
}

export default About
