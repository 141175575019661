import './App.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Nav() {
  return (
    <nav className="nav-bar">
      <Link to="/" className="nav-bar-logo">
        OFFHELL{' '}
        <FontAwesomeIcon icon="fa-skull" className="fa-pull-right fa-spin" />
      </Link>
      <ul className="nav-links">
        <Link className="nav-style" to="/about" style={{ textDecoration: 'none' }}>
          <li>About</li>
        </Link>
        <Link className="nav-style" to="/mint" style={{ textDecoration: 'none' }}>
          <li>Mint</li>
        </Link>
      </ul>
    </nav>
  )
}

export default Nav
